import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Customer, Session } from '../models/identity.model';
import { HttpService } from '../../core/services/http.service';
import { environment } from '../../../environments/environment';

@Injectable({
	providedIn: 'root',
})
export class IdentityService {
	constructor(private httpService: HttpService) {}

	getCustomer(): Observable<Customer> {
		return this.httpService.get(environment.trovataApi + '/identity/customer');
	}

	getSession(): Observable<Session> {
		return this.httpService.get(environment.trovataApi + '/session');
	}
}
